import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";
import Header from "../components/AboutPage/Header/Header";
import { log } from "../utils";

const Section2 = lazy(() =>
  import("../components/AboutPage/Section2/Section2")
);
const Section3 = lazy(() =>
  import("../components/AboutPage/Section3/Section3")
);
// const Team = lazy(() => import("../components/AboutPage/Team/Team"));
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function About() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Header />
      <Suspense fallback={<div>Loading ...</div>}>
        <Section2 />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Section3 />
      </Suspense>
      {/* <Suspense fallback={<div>Loading Team...</div>}>
        <Team />
      </Suspense> */}
      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default About;
