import React, { Suspense, lazy } from "react";
import Header from "../components/MeteringSolutions/Header/Header";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";

// Lazy load components
const Section1 = lazy(() =>
  import("../components/MeteringSolutions/Sections/Section1")
);
const Section2 = lazy(() =>
  import("../components/MeteringSolutions/Sections/Section2")
);
const Section3 = lazy(() =>
  import("../components/MeteringSolutions/Sections/Section3")
);
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function MeteringSolutions() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      <Suspense fallback={<div>Loading content...</div>}>
        <Section1 />
      </Suspense>
      <Suspense fallback={<div>Loading content...</div>}>
        <Section2 />
      </Suspense>
      <Suspense fallback={<div>Loading content...</div>}>
        <Section3 />
      </Suspense>
      <Suspense fallback={<div>Loading content...</div>}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default MeteringSolutions;
