import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";
import Header from "../components/Contact/Header/Header";

const Section = lazy(() => import("../components/Contact/Section/Section"));
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function Contact() {
  return (
    <div
      className="container-fluid p-0"
      style={{ fontFamily: '"Montserrat", sans-serif' }}
    >
      <Header />
      <Suspense fallback={<div>Loading ...</div>}>
        <Section />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default Contact;
