import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";
import Header from "../components/EnergyAdvisory/Header/Header";

const Section1 = lazy(() =>
  import("../components/EnergyAdvisory/Section1/Section1")
);
const Section2 = lazy(() =>
  import("../components/EnergyAdvisory/Section2/Section2")
);
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function EnergyAdvisory() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Header />
      <Suspense fallback={<div>Loading ...</div>}>
        <Section1 />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Section2 />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Support mail="sales@smartechadvisory.com" id="contactFormSection" />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default EnergyAdvisory;
