import React, { Suspense, lazy } from "react";
import Social from "../components/Common/Social/Social";
import Header from "../components/Consultancy/Header/Header";
import Chatbot from "../components/Common/Chatbot/Chatbot";

// Lazy load components
const Section2 = lazy(() =>
  import("../components/Consultancy/Section2/Section2")
);
const Services = lazy(() =>
  import("../components/Consultancy/Services/Services")
);
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function Consultancy() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />
      <Suspense fallback={<div>Loading ...</div>}>
        <Section2 />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Services />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Support mail="sales@smartechadvisory.com" id="contactUsForm" />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default Consultancy;
