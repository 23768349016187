import React, { Suspense, lazy } from "react";
import Header from "../components/Technology/Header/Header";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";

const Section1 = lazy(() =>
  import("../components/Technology/Sections/Section1")
);
const Section2 = lazy(() =>
  import("../components/Technology/Sections/Section2")
);
const Section3 = lazy(() =>
  import("../components/Technology/Sections/Section3")
);
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function Technology() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />

      <Suspense fallback={<div>Loading ...</div>}>
        <Section1 />
      </Suspense>

      <Suspense fallback={<div>Loading ...</div>}>
        <Section2 />
      </Suspense>

      <Suspense fallback={<div>Loading ...</div>}>
        <Section3 />
      </Suspense>

      <Suspense fallback={<div>Loading ...</div>}>
        <Support mail="sales@smartechadvisory.com" />
      </Suspense>

      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>

      <Social />
      <Chatbot />
    </div>
  );
}

export default Technology;
