import React, { Suspense, lazy } from "react";
import Header from "../components/HomePage/Header/Header";
import Features from "../components/HomePage/Features/Features";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";

const WhyUs = lazy(() => import("../components/HomePage/WhyUs/WhyUs"));
const Stats = lazy(() => import("../components/HomePage/Stats/Stats"));
const Slides = lazy(() => import("../components/HomePage/Slides/Slides"));
const Customers = lazy(() =>
  import("../components/HomePage/Customers/Customers")
);
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

export default function Home() {
  return (
    <div
      style={{
        fontFamily: '"Montserrat", sans-serif',
        backgroundColor: "#eee",
      }}
    >
      <Header />
      <Features />
      <Suspense fallback={<div>Loading...</div>}>
        <WhyUs />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Stats />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Slides />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Customers />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}
