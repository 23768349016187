import React, { Suspense, lazy } from "react";
import Header from "../components/SerIT/Header/Header";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";

const Section1 = lazy(() => import("../components/SerIT/Section1/Section1"));
const Section2 = lazy(() => import("../components/SerIT/Section2/Section2"));
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function SerIT() {
  return (
    <div
      className="container-fluid p-0"
      style={{ fontFamily: '"Montserrat", sans-serif' }}
    >
      <Header />
      <Suspense fallback={<div>Loading ...</div>}>
        <Section1 />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Section2 />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>
      <Social />
      <Chatbot />
    </div>
  );
}

export default SerIT;
