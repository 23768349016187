import React, { Suspense, lazy } from "react";
import Header from "../components/Solar/Header/Header";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";

const Section1 = lazy(() => import("../components/Solar/Section1/Section1"));
const Services = lazy(() => import("../components/Solar/Services/Services"));
const WhyUs = lazy(() => import("../components/Solar/WhyUs/WhyUs"));
const Projects = lazy(() => import("../components/Solar/Projects/Projects"));
const Calculator = lazy(() =>
  import("../components/Solar/Calculator/Calculator")
);
const Support = lazy(() => import("../components/Common/Support/Support"));
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function Solar() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />

      <div>
        <Suspense fallback={<div>Loading ...</div>}>
          <Section1 />
        </Suspense>

        <Suspense fallback={<div>Loading ...</div>}>
          <Services />
        </Suspense>

        <Suspense fallback={<div>Loading ...</div>}>
          <WhyUs />
        </Suspense>

        <Suspense fallback={<div>Loading ...</div>}>
          <Projects />
        </Suspense>

        <Suspense fallback={<div>Loading ...</div>}>
          <Calculator />
        </Suspense>

        <Suspense fallback={<div>Loading ...</div>}>
          <Support mail="solar@smartechadvisory.com" />
        </Suspense>

        <Suspense fallback={<div>Loading ...</div>}>
          <Footer />
        </Suspense>

        <Social />
        <Chatbot />
      </div>
    </div>
  );
}

export default Solar;
