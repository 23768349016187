import React, { Suspense, lazy } from "react";
import Header from "../components/SerConsultancy/Header/Header";
import Social from "../components/Common/Social/Social";
import Chatbot from "../components/Common/Chatbot/Chatbot";

const Section1 = lazy(() =>
  import("../components/SerConsultancy/Sections/Section1")
);
const Section2 = lazy(() =>
  import("../components/SerConsultancy/Sections/Section2")
);
const Section3 = lazy(() =>
  import("../components/SerConsultancy/Sections/Section3")
);
const Footer = lazy(() => import("../components/Common/Footer/Footer"));

function SerConsultancy() {
  return (
    <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <Header />

      <div style={{ backgroundColor: "#eee" }}>
        <Suspense fallback={<div>Loading ...</div>}>
          <Section1 />
        </Suspense>

        <Suspense fallback={<div>Loading ...</div>}>
          <Section2 />
        </Suspense>
      </div>

      <Suspense fallback={<div>Loading ...</div>}>
        <Section3 />
      </Suspense>

      <Suspense fallback={<div>Loading ...</div>}>
        <Footer />
      </Suspense>

      <Social />
      <Chatbot />
    </div>
  );
}

export default SerConsultancy;
